<template>
    <div>
        <fclt-plugin-status
            ref="fcltPluginStatus"
            :title="'VDS 상태정보'"
            :fcltId="this.fcltData.fcltId"
            :statHederInfo="statHederInfo"
        >
        </fclt-plugin-status>
        <div class="dk-card">
          <table class="el-table el-table--mini">
            <tr>
              <th>시설물 명칭</th>
              <td>{{fcltData.fcltName}}</td>
            </tr>
            <tr>
              <th>시설물 유형</th>
              <td>{{fcltData.fcltTypeName}}</td>
            </tr>
            <tr>
              <th>도로명 주소</th>
              <td>{{fcltData.setRoadAddr}}</td>
            </tr>
            <tr>
              <th>지번 주소</th>
              <td>{{fcltData.setNumberAddr}}</td>
            </tr>
            <tr>
              <th>경도</th>
              <td>{{fcltData.gisLng}}</td>
            </tr>
            <tr>
              <th>위도</th>
              <td>{{fcltData.gisLat}}</td>
            </tr>
            <tr v-for="(ftData, index) in checkFcltData" :key="index">
              <th>{{ftData.label}}</th>
              <td>{{getFcltDataValue(ftData)}}</td>
            </tr>
          </table>
        </div>
    </div>
</template>
<script>
import fcltPluginStatus from '@/traffic/widget/fcltPluginStatus.vue'
import { async } from 'q';

export default {
    components: {        
        'fclt-plugin-status': fcltPluginStatus,
    },
    name: "dk-vds",
    label: "상태정보",    
    created() {
        let me = this;
        me.updateStatus();
    },
    mounted() {},
    data() {
        return {
        
            statHederInfo: [{
                key:"powerStatus", 
                name:"전원",
                textInfo: {
                    ERR:'에러',
                    OFF:'꺼짐',
                    ON:'정상'
                },
            },],
        };
    },
    props: {
        fcltData: {
            required:true            
        }
    },
    methods: {
        updateStatus() {
          this.$refs.fcltPluginStatus.updateFcltStatusInfo(this.fcltData.fcltId);
        }
        
    }
}
</script>
